/* eslint-disable import/no-anonymous-default-export */
import axios from 'axios';
const URI = 'https://cdn.radyospor.com';
//const URI = 'http://localhost:6100';
const CDN = 'http://localhost:6055';

export default {
	settings: () =>
		axios
			.get(`${URI}/settings/all`)
			.then((res) => res.data)
			.catch((err) => err),
	news: () =>
		axios
			.get('https://io.ajansspor.com/content/headline')
			.then((res) => res.data)
			.catch((err) => err),
	contact: (data) =>
		axios
			.post(`${URI}/contact/add`, data)
			.then((res) => res.data)
			.catch((err) => err),
	client: () =>
		axios
			.get('https://api.db-ip.com/v2/free/self')
			.then((res) => res.data)
			.catch((err) => err),
	frequency: () =>
		axios
			.get(`${URI}/frequency/all`)
			.then((res) => res.data)
			.catch((err) => err),
	broadcasters: () =>
		axios
			.get(`${URI}/broadcaster/all`)
			.then((res) => res.data)
			.catch((err) => err),

	broadcaster: (id) =>
		axios
			.get(`${URI}/broadcaster/get?id=${id}`)
			.then((res) => res.data)
			.catch((err) => err),
	streaming: (day) =>
		axios
			.get(`${URI}/streaming/daily?day=${day}`)
			.then((res) => res.data)
			.catch((err) => err),
	live: () =>
		axios
			.get(`${URI}/streaming/live`)
			.then((res) => res.data)
			.catch((err) => err),
	pointstatus: (id) =>
		axios
			.get(`https://ajsapicache.mediatriple.net/soccer/standing/203/2023`)
			.then((res) => res.data)
			.catch((err) => err),
	programs: () =>
		axios
			.get(`${URI}/program/all`)
			.then((res) => res.data)
			.catch((err) => err),
	program: (id) =>
		axios
			.get(`${URI}/program/get?programId=${id}`)
			.then((res) => res.data)
			.catch((err) => err),
	liveMatch: (date) =>
		axios
			.get(`https://api.ajansspor.com/apiv2/matches/soccer/getLiveMacthes?date=${date}`)
			.then((res) => res.data)
			.catch((err) => err),
	facebook: (data) =>
		axios
			.post(`${URI}/auth/facebook`, data)
			.then((res) => res.data)
			.catch((err) => err),
	login: (data) =>
		axios
			.post(`${URI}/users/login`, data)
			.then((res) => res.data)
			.catch((err) => err),
	register: (data) =>
		axios
			.post(`${URI}/users/add`, data)
			.then((res) => res.data)
			.catch((err) => err),
	updateUser: (data) =>
		axios
			.post(`${URI}/users/update`, data)
			.then((res) => res.data)
			.catch((err) => err),
	setFavoritePrograms: (data) =>
		axios
			.post(`${URI}/favorites/program`, data)
			.then((res) => res.data)
			.catch((err) => err),
	getFavoriteProgram: (id) =>
		axios
			.get(`${URI}/favorites/program${id}`)
			.then((res) => res.data)
			.catch((err) => err),
	setFavoriteMatches: (data) =>
		axios
			.post(`${URI}/favorites/match`, data)
			.then((res) => res.data)
			.catch((err) => err),
	getFavoriteMatch: (id) =>
		axios
			.get(`${URI}/favorites/match?userId=${id}`)
			.then((res) => res.data)
			.catch((err) => err),

	setFavoriteBroadcasters: (data) =>
		axios
			.post(`${URI}/favorites/broadcaster`, data)
			.then((res) => res.data)
			.catch((err) => err),
	getFavoriteBroadcaster: (id) =>
		axios
			.get(`${URI}/favorites/broadcaster?userId=${id}`)
			.then((res) => res.data)
			.catch((err) => err),
	setFavoritePodcasts: (data) =>
		axios
			.post(`${URI}/favorites/podcast`, data)
			.then((res) => res.data)
			.catch((err) => err),
	getFavoritePodcast: (id) =>
		axios
			.get(`${URI}/favorites/podcast${id}`)
			.then((res) => res.data)
			.catch((err) => err),
	getMatchDetail: (id) =>
		axios
			.get(`https://api.ajansspor.com/apiv2/live/soccer/match/${id}`)
			.then((res) => res.data)
			.catch((err) => err),
	uploadImage: (data) =>
		axios
			.post(`${CDN}/user`, data, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			})
			.then((res) => res.data)
			.catch((err) => err),
	updateImage: (data) =>
		axios
			.post(`${URI}/users/update/image`, data)
			.then((res) => res.data)
			.catch((err) => err),
	lastPodcast: () =>
		axios
			.get(`${URI}/podcast/last`)
			.then((res) => res.data)
			.catch((err) => err),
	mostPodcast: () =>
		axios
			.get(`${URI}/podcast/most`)
			.then((res) => res.data)
			.catch((err) => err),
	privatePodcast: () =>
		axios
			.get(`${URI}/podcast/private`)
			.then((res) => res.data)
			.catch((err) => err),
	getPodcast: (id) =>
		axios
			.get(`${URI}/podcast/get?id=${id}`)
			.then((res) => res.data)
			.catch((err) => err),
	logs: (data) =>
		axios
			.post(`${URI}/users/logs`, data)
			.then((res) => res.data)
			.catch((err) => err),
	ip: () =>
		axios
			.get('https://api64.ipify.org/?format=json')
			.then((res) => res.data)
			.catch((err) => err),
	setListenedPodcast: (data) =>
		axios
			.post(`${URI}/users/listenedpodcast`, data)
			.then((res) => res.data)
			.catch((err) => err),
	getListenedPodcast: (data) =>
		axios
			.post(`${URI}/users/getListenedPodcast`, data)
			.then((res) => res.data)
			.catch((err) => err),
};
