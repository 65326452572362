/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-script-url */
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { logOut } from '../utils';
import HamburgerMenu from './HamburgerMenu';
import LoginModal from './LoginModal';

const Header = ({ page, fetchSettings, settings, fetchLiveStreaming, fetchUser, user, setPlayerType, setPlayLive, setPlayPodcast, setPlayedPodcast }) => {
	const [isMenuOpen, setMenuOpen] = useState(false);
	const [searchBox, setSearchBox] = useState(false);
	const [searchText, setSearchText] = useState('');
	const [headerFix, setHeaderFix] = useState(false);
	const inputRef = useRef(null);

	const menuStatus = (status) => {
		setMenuOpen(status);
	};

	const searchOpen = (status) => {
		toggleSearch();
	};

	const search = (e) => {
		const { value } = e.target;
		setSearchText(value);

		if (value.length > 0) {
			window.history.pushState({}, null, `http://localhost:3000/search?s=${value}`);
		} else {
			window.history.pushState({}, null, `http://localhost:3000`);
		}
	};

	const toggleSearch = () => {
		if (searchBox) {
			setSearchBox(false);
			setSearchText('');
			window.history.pushState({}, null, `http://localhost:3000`);
		} else {
			setSearchBox(true);
			setTimeout(() => {
				inputRef.current.focus();
			}, 300);
		}
	};

	const submitSearchForm = (e) => {
		e.preventDefault();

		window.location.href = `/search?s=${searchText}`;
	};

	const changePlayerType = (type) => {
		// canlı yayına geçiş
		setPlayerType(type);
		setPlayPodcast(false);
		setPlayLive(true);
		setPlayedPodcast(0);
	};

	useEffect(() => {
		fetchSettings();
		fetchUser();

		if (searchBox) {
			document.onkeydown = (e) => {
				e = e || window.event;

				if (e.keyCode === 27) {
					toggleSearch();
					window.history.pushState({}, null, `http://localhost:3000`);
				}
			};
		} else {
			window.onkeypress = (e) => {
				if (!(e.which === 115 && e.ctrlKey) && !(e.which === 6)) return true;
				toggleSearch();
				window.history.pushState({}, null, `http://localhost:3000`);

				e.preventDefault();
				return false;
			};
		}

		// Header Animation
		window.addEventListener('scroll', () => {
			const scrollPosition = window.scrollY;
			const windowWidth = window.outerWidth;

			if (scrollPosition >= (windowWidth > 991 ? 106 : 76) && windowWidth > 768) {
				setHeaderFix(true);
				document.body.classList.add('fixed-header');
			} else {
				setHeaderFix(false);
				document.body.classList.remove('fixed-header');
			}
		});
	}, [searchBox]);

	useEffect(() => {
		fetchLiveStreaming();

		setInterval(() => {
			fetchLiveStreaming();
		}, 10000);
	}, []);

	const logoutUser = () => {
		logOut();
		setTimeout(() => {
			window.location.reload();
		}, 1500);
	};

	return (
		<div className={`header${headerFix ? ' fixed' : ''}`}>
			<div className="container">
				<div className="header-top">
					<div className="header-mobile-menu">
						<HamburgerMenu menuStatus={(status) => menuStatus(status)} />
					</div>

					<ul className="header-social">
						<li>
							<a href={settings.facebook} target="_blank" rel="noreferrer">
								<img src="/assets/images/facebook.svg" alt="" />
							</a>
						</li>
						<li>
							<a href={settings.instagram} target="_blank" rel="noreferrer">
								<img src="/assets/images/instagram.svg" alt="" />
							</a>
						</li>
						<li>
							<a href={settings.twitter} target="_blank" rel="noreferrer">
								<img src="/assets/images/twitter.svg" alt="" />
							</a>
						</li>
					</ul>

					<div className="logo">
						<a href="/">
							<img src="/assets/images/logo.svg" alt="Radyospor" />
						</a>
					</div>

					<div className="header-right">
						<div className="header-live" onClick={() => changePlayerType('live')}>
							<span>Canlı Dinle</span>
						</div>

						<a href={`tel:+902123630434`} className="live-contact">
							<svg version="1.1" id="phone" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 82 82" style={{ enableBackground: 'new 0 0 82 82' }}>
								<path
									d="M64.5,78.2c1.7-1.9,3.6-3.6,5.4-5.4c2.6-2.7,2.7-5.9,0-8.6c-3.1-3.2-6.3-6.3-9.4-9.4
							c-2.6-2.6-5.8-2.6-8.4,0c-2,1.9-3.9,3.9-5.9,5.9c-0.1,0.1-0.3,0.2-0.4,0.3l-1.3,1.3c-0.4,0.2-0.7,0.2-1.2,0
							c-1.3-0.7-2.6-1.2-3.8-2c-5.7-3.6-10.5-8.2-14.7-13.4c-2.1-2.6-4-5.3-5.3-8.4c-0.2-0.5-0.2-0.9,0.1-1.3l1.3-1.3
							c0.1-0.1,0.1-0.2,0.2-0.3c0.6-0.6,1.2-1.1,1.8-1.7c1.4-1.3,2.7-2.7,4.1-4.1c2.7-2.7,2.7-5.9,0-8.6c-1.5-1.5-3.1-3.1-4.6-4.6
							c-1.6-1.6-3.2-3.2-4.8-4.8c-2.6-2.5-5.8-2.5-8.4,0c-2,1.9-3.9,3.9-5.9,5.9c-1.9,1.8-2.8,3.9-3,6.5c-0.3,4.1,0.7,8,2.1,11.8
							C5.2,43.8,9.6,50.7,15,57.1c7.2,8.6,15.9,15.4,26,20.4c4.6,2.2,9.3,3.9,14.4,4.2C58.9,81.8,62,81,64.5,78.2z"
								></path>

								<path
									d="M41.1,15.7
							c-0.7,0-1.5,0.1-2.2,0.4c-1.7,0.8-2.5,2.8-2,4.8c0.4,1.8,2,3,3.9,3c4.6,0.1,8.6,1.5,12,4.6c3.7,3.4,5.4,7.7,5.6,12.8
							c0,0.9,0.4,1.9,0.9,2.6c1.1,1.5,3,1.9,4.8,1.2c1.6-0.6,2.5-2,2.5-3.9c-0.1-7-2.6-12.9-7.5-18.1C54.1,18.4,48.1,15.8,41.1,15.7z"
								></path>

								<path
									d="M69,11.4c8.5,8.7,12.5,18.1,12.8,29.1c0.1,2.5-1.5,4.2-3.9,4.3c-2.6,0.1-4.3-1.4-4.4-4c-0.1-5.4-1.4-10.5-4-15.2
							C63.5,14.9,54.2,9.3,42,8.6c-1.4-0.1-2.6-0.2-3.6-1.3c-1.2-1.4-1.3-3-0.7-4.6c0.7-1.6,2-2.4,3.8-2.4c8,0.1,15.3,2.4,22,6.8
							C65.7,8.6,67.8,10.4,69,11.4z"
								></path>
							</svg>

							<span>0212 363 04 34</span>
						</a>
					</div>

					{/* <div className="header-mobile-search">
						<SearchButton searchStatus={searchBox} searchOpen={(status) => searchOpen(status)} />
					</div> */}
				</div>

				<div className={`header-bottom${isMenuOpen ? ' opened' : ''}`}>
					<nav>
						<ul>
							{[
								{ title: 'Anasayfa', link: '/', rel: 'dashboard' },
								{ title: 'Yayın Akışı', link: '/yayin-akisi', rel: 'streaming' },
								{ title: 'Podcastler', link: '/podcastler', rel: 'podcasts' },
								{ title: 'Haberler', link: '/haberler', rel: 'news' },
								// { title: 'Canlı Skor', link: '/canli-skor', rel: 'livescore' },
								{ title: 'Programcılar', link: '/programcilar', rel: 'broadcasters' },
								{ title: 'Frekanslar', link: '/frekanslar', rel: 'frequencies' },
							].map((menu, i) => (
								<li key={i}>
									<a href={menu.link} className={page === menu.rel ? 'active' : ''}>
										{menu.title}
									</a>
								</li>
							))}
							{!isMenuOpen &&
								(user && user.token ? (
									<li className="dropdown">
										<button>
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
												<path d="M332.64 64.58C313.18 43.57 286 32 256 32c-30.16 0-57.43 11.5-76.8 32.38-19.58 21.11-29.12 49.8-26.88 80.78C156.76 206.28 203.27 256 256 256s99.16-49.71 103.67-110.82c2.27-30.7-7.33-59.33-27.03-80.6zM432 480H80a31 31 0 01-24.2-11.13c-6.5-7.77-9.12-18.38-7.18-29.11C57.06 392.94 83.4 353.61 124.8 326c36.78-24.51 83.37-38 131.2-38s94.42 13.5 131.2 38c41.4 27.6 67.74 66.93 76.18 113.75 1.94 10.73-.68 21.34-7.18 29.11A31 31 0 01432 480z" />
											</svg>
										</button>

										<ul>
											<li>
												<a href="/profil">{user.fullname}</a>
											</li>
											<li>
												<a href="/dinlediklerim">Dinlediklerim</a>
											</li>
											<li>
												<button onClick={() => logoutUser()}>Çıkış</button>
											</li>
										</ul>
									</li>
								) : (
									<>
										<li>
											<a href="/giris">Giriş Yap</a>
										</li>
										<li>
											<a href="/kayit">Kayıt Ol</a>
										</li>
									</>
								))}

							{isMenuOpen && (
								<>
									<li>
										<a href="/profil">{user.fullname}</a>
									</li>
									<li>
										<a href="/dinlediklerim">Dinlediklerim</a>
									</li>
									<li>
										<button onClick={() => logoutUser()}>Çıkış</button>
									</li>
								</>
							)}

							{/* <li onClick={() => toggleSearch()}>
								<button>
									<ion-icon name="search"></ion-icon>
								</button>
							</li> */}
						</ul>
					</nav>
				</div>
			</div>

			<div className={`search-container${searchBox ? ' opened' : ''}`}>
				<form action="" onSubmit={submitSearchForm}>
					<div className="form-group">
						<div className="search-icon">
							<ion-icon name="search-outline"></ion-icon>
						</div>

						<input type="text" placeholder="Program, Podcast yada Yayıncı ara..." ref={inputRef} value={searchText} onChange={(e) => search(e)} />

						<div className="search-close" onClick={() => toggleSearch()}>
							<ion-icon name="close-outline"></ion-icon>
						</div>
					</div>
					<small>* kapatmak için ESC tuşuna basın. Açmak için ALT ve F tuşlarına aynı anda basın. (MacOS için CTRL ve F)</small>
				</form>
			</div>

			{/* <LoginModal /> */}
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		settings: state.Settings.settings,
		live: state.LiveStream.live,
		user: state.Login.user,
	};
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
	const { dispatch } = dispatchProps;
	const { actions: Settings } = require('../redux/SettingsRedux');
	const { actions: LiveStream } = require('../redux/LiveRedux');
	const { actions: Login } = require('../redux/LoginRedux');
	const { actions: Play } = require('../redux/PlayRedux');

	return {
		...ownProps,
		...stateProps,
		fetchSettings: () => Settings.fetchSettings(dispatch),
		fetchLiveStreaming: () => LiveStream.fetchLiveStreaming(dispatch),
		fetchUser: () => Login.fetchUser(dispatch),
		setPlayLive: (status) => Play.setPlayLive(dispatch, status),
		setPlayerType: (type) => Play.setPlayerType(dispatch, type),
		setPlayedPodcast: (id) => Play.setPlayedPodcast(dispatch, id),
		setPlayPodcast: (status) => Play.setPlayPodcast(dispatch, status),
	};
};

export default connect(mapStateToProps, null, mergeProps)(Header);
