import api from '../api';

const types = {
	FETCH_POINTSTATUS: 'FETCH_POINTSTATUS',
	FETCH_POINTSTATUS_SUCCESS: 'FETCH_POINTSTATUS_SUCCESS',
	FETCH_POINTSTATUS_FAILURE: 'FETCH_POINTSTATUS_FAILURE',
};

export const actions = {
	fetchPointStatus: (dispatch, id) => {
		dispatch({ type: types.FETCH_POINTSTATUS });

		api.pointstatus(id)
			.then((res) => dispatch({ type: types.FETCH_POINTSTATUS_SUCCESS, pointstatus: res.data[0] }))
			.catch((error) => dispatch({ type: types.FETCH_POINTSTATUS_FAILURE, error }));
	},
};

const initialState = {
	isFetching: true,
	error: null,
	pointstatus: [],
};

export const reducer = (state = initialState, action) => {
	const { error, type, pointstatus } = action;

	switch (type) {
		case types.FETCH_POINTSTATUS:
			return { ...initialState };
		case types.FETCH_POINTSTATUS_SUCCESS:
			return { ...state, pointstatus, isFetching: false, error };
		case types.FETCH_POINTSTATUS_FAILURE:
			return { ...state, error, isFetching: false };
		default:
			return state;
	}
};
