import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Oval } from 'react-loader-spinner';
import { Helmet } from 'react-helmet';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';

import { setToken } from '../utils';

const validationSchema = Yup.object({
	email: Yup.string().email().required('Eposta adresi boş olamaz.'),
	password: Yup.string().required('Şifre boş olamaz.'),
});

const Login = ({ settings, user, fetchLoginUser, error }) => {
	const [status, setStatus] = useState(false);
	//const [alert, setAlert] = useState('');
	const [captcha, setCaptcha] = useState();

	useEffect(() => {
		document.body.classList.add('p0');
	}, []);

	useEffect(() => {
		setStatus(false);
		if (Object.keys(user).length > 0) {
			setToken(user.token);
			setTimeout(() => {
				window.location.reload();
			}, 100);
		}
	}, [user]);

	// const os = () => {
	// 	let OSName = 'Unknown OS';
	// 	if (navigator.appVersion.indexOf('Win') !== -1) OSName = 'Windows';
	// 	if (navigator.appVersion.indexOf('Mac') !== -1) OSName = 'MacOS';
	// 	if (navigator.appVersion.indexOf('X11') !== -1) OSName = 'UNIX';
	// 	if (navigator.appVersion.indexOf('Linux') !== -1) OSName = 'Linux';

	// 	return OSName;
	// };

	// const browser = () => {
	// 	if ((navigator.userAgent.indexOf('Opera') || navigator.userAgent.indexOf('OPR')) !== -1) {
	// 		return 'Opera';
	// 	} else if (navigator.userAgent.indexOf('Chrome') !== -1) {
	// 		return 'Chrome';
	// 	} else if (navigator.userAgent.indexOf('Safari') !== -1) {
	// 		return 'Safari';
	// 	} else if (navigator.userAgent.indexOf('Firefox') !== -1) {
	// 		return 'Firefox';
	// 	} else if (navigator.userAgent.indexOf('MSIE') !== -1 || !!document.documentMode === true) {
	// 		return 'Internet Explorer';
	// 	} else {
	// 		return 'Tanımsız';
	// 	}
	// };

	const recaptchaChange = (value) => {
		setCaptcha(value);
	};

	const { handleSubmit, handleChange, values, errors } = useFormik({
		initialValues: {
			email: '',
			password: '',
		},
		validationSchema,
		onSubmit: async (values) => {
			if (captcha) {
				setStatus(true);
				fetchLoginUser({ ...values });
			} else {
				alert('Robot olmadığınızı doğrulayın.');
			}
		},
	});

	return (
		<>
			<Helmet>
				<title>Giriş yap yada kayıt ol | Radyospor - Türkiye'nin İlk Spor Radyosu</title>
			</Helmet>

			<div className="login-page">
				<div className="login-carousel">
					<div className="login-image">
						<img src="/assets/images/login.webp" alt="" />
					</div>

					<div className="login-title">
						<span>Radyospor</span>
						Sporun Tek Adresi
					</div>
				</div>

				<div className="login-container">
					<div className="login-logo">
						<a href="/">
							<img src="/assets/images/logo.svg" alt="" />
						</a>
					</div>

					<div className="login-form">
						<h1>Giriş Yap</h1>

						<form action="" onSubmit={handleSubmit}>
							<input type="text" name="email" onChange={handleChange} value={values.email} className={errors.email && 'error'} placeholder="Email" />
							<input type="password" name="password" onChange={handleChange} value={values.password} className={errors.password && 'error'} placeholder="Şifre" />

							<div className="g-recaptcha">
								<ReCAPTCHA sitekey="6LdbGg0dAAAAAEAjn9-Oytdcrio68vTps3WcWigC" onChange={recaptchaChange} />
							</div>

							{Object.keys(errors).length > 0 && <div className="form-text error">Bir yada birden fazla boş olan mevcut.</div>}

							{error && <div className="form-text success">{error}</div>}

							<button type="submit">{status ? <Oval color="#fff" height={16} width={16} /> : 'Giriş'}</button>
						</form>
					</div>

					<div className="login-social-media">
						<ul>
							<li>
								<a href={settings.facebook} target="_blank" rel="noreferrer">
									<img src="/assets/images/facebook.svg" alt="" />
								</a>
							</li>
							<li>
								<a href={settings.instagram} target="_blank" rel="noreferrer">
									<img src="/assets/images/instagram.svg" alt="" />
								</a>
							</li>
							<li>
								<a href={settings.twitter} target="_blank" rel="noreferrer">
									<img src="/assets/images/twitter.svg" alt="" />
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		settings: state.Settings.settings,
		user: state.Login.user,
		error: state.Login.error,
	};
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
	const { dispatch } = dispatchProps;
	const { actions: Settings } = require('../redux/SettingsRedux');
	const { actions: Login } = require('../redux/LoginRedux');

	return {
		...ownProps,
		...stateProps,
		fetchSettings: () => Settings.fetchSettings(dispatch),
		fetchLoginUser: (data) => Login.fetchLoginUser(dispatch, data),
	};
};

export default connect(mapStateToProps, null, mergeProps)(Login);
